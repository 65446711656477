import React, { useEffect, useRef, useState } from "react";
import { getSignedUrlOfS3, updateProject } from "api";
import { useMergeState } from "utils/custom-hooks";
import { ProjectStatus as status } from "utils/constants";
import logo from "../../../assets/imgs/stack.svg";
import moment from "moment";
import ConfirmInActivateProjectDialog from "components/ConfirmInActivateProjectDialog";
import { wrapNameTwoLetters } from "utils/common";

const projectType = [
  {
    title: "Applied",
    class: "fa-sharp fa-regular fa-check",
    color: "text_secondary bg_light_secondary",
  },
  {
    title: "Requested to Interview",
    class: "fal fa-clock",
    color: "text_orange bg_light_orange",
  },
  {
    title: "Ongoing Project",
    class: "fa-sharp fa-solid fa-arrow-rotate-left",
    color: "text_green bg_light_green",
  },
];

const buttonStyles = [
  {
    enableStyle: "btn bg_secondary btn_small text_secondary mob_100",

    // enableStyle: "btn bg_light_secondary btn_small text_secondary mob_100",
    disableStyles: "btn bg_secondary btn_small text_secondary mob_100",
  },
  {
    enableStyle: "btn bg_secondary btn_small text_secondary mob_100",
    disableStyles: "btn bg_light btn_small text_black mob_100",
  },
  {
    enableStyle: "btn bg_light btn_small text_black mob_100",
    disableStyles: "btn bg_light btn_small text_black mob_100",
  },
];

type Props = {
  type: number;
  buttonTitle: string;
  buttonOnClick?: () => void;
  showStatusButton?: boolean;
  statusChangeButton?: boolean;
  projectId?: string;
  hourlyRate?: number;
  showDeclineButton?: boolean;
  handleDecline?: () => void;
  jobDocumentUrl?: string;
  checkStatus?: string;
  title: string;
  titleType: string;
  duration?: number;
  declineButtonTitle?: string;
  disablePrimaryButton?: boolean;
  totalApplicants?: number;
  lastUpdate?: string;
  companyLogo?: any;
  business?: any;
  handleChangeProjectStatus?: (status: string) => void;
};

const ProjectStatusComp = ({
  type,
  buttonTitle,
  projectId = "-1",
  buttonOnClick = undefined,
  showStatusButton,
  statusChangeButton,
  hourlyRate,
  showDeclineButton = false,
  handleDecline = () => {},
  jobDocumentUrl = "",
  checkStatus = "",
  title,
  titleType,
  duration = 0,
  declineButtonTitle = "Decline",
  disablePrimaryButton = false,
  totalApplicants,
  lastUpdate,
  companyLogo = null,
  business = null,
  handleChangeProjectStatus = () => {},
}: Props) => {
  const [state, setState] = useMergeState({
    showProjectStatusButton: showStatusButton,
    checked: false,
    checkValue: "",
    shouldShowConfirmInActivateProjectDialog: false,
  });
  const isBusinessName = business && business.name;

  useEffect(() => {
    setState({
      checked: checkStatus === status.ACTIVE ? true : false,
      checkValue: checkStatus === status.ACTIVE ? "Yearly" : "Monthly",
    });
  }, [checkStatus]);
  // const checkRef = useRef<HTMLInputElement>(null);

  const handleOpenConfirmInActivateProjectDialog = () => {
    setState({ shouldShowConfirmInActivateProjectDialog: true });
  };

  const handleCloseConfirmInActivateProjectDialog = () => {
    setState({ shouldShowConfirmInActivateProjectDialog: false });
  };

  const handleProjectStatus = async (status: string) => {
    handleCloseConfirmInActivateProjectDialog();
    handleChangeProjectStatus(status);
  };

  const initials = (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(
          business?.name?.split(" ")[0],
          business?.name?.split(" ")[1]
        ).toUpperCase()}
      </p>
    </div>
  );

  return (
    <div>
      <div className="d-flex align-items-center">
        {companyLogo ? (
          <div className="lg_stack_logo">
            <img src={companyLogo} />
          </div>
        ) : isBusinessName ? (
          initials
        ) : (
          <div className="lg_stack_logo">
            <img src={logo} />
          </div>
        )}
        <div>
          <p className="text_black fw-bold fs-5 ms-3 lh-base">{title}</p>
          {isBusinessName && (
            <p className="font_14 text_primary ms-3 text-start">
              {business?.name || ""}
            </p>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3 flex-wrap">
        {showStatusButton ? (
          <div className="d-flex align-items-center flex-wrap">
            <p className="fw-bold text_primary me-4">Project Status:</p>
            {statusChangeButton ? (
              <div className="switches_container mt-2 mt-md-0">
                <input
                  type="radio"
                  id="switchMonthly"
                  name="switchPlan"
                  value="Monthly"
                  checked={state?.checkValue === "Monthly"}
                  onChange={(e) => {
                    handleOpenConfirmInActivateProjectDialog();
                    // handleChangeProjectStatus(status.IN_ACTIVE);
                    // setState({
                    //   checkValue: "Monthly",
                    // });
                  }}
                />
                <input
                  type="radio"
                  id="switchYearly"
                  name="switchPlan"
                  value="Yearly"
                  checked={state?.checkValue === "Yearly"}
                  // checked={state?.chacked === true}
                  onChange={(e) => {
                    setState({ checkValue: "Yearly" });
                    handleChangeProjectStatus(status.ACTIVE);
                  }}
                />
                <label htmlFor="switchMonthly">OFFLINE</label>
                <label htmlFor="switchYearly">ONLINE</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div>OFFLINE</div>
                    <div>ONLINE</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text_green bg_light_green mb-2 mb-md-0 px-2 py-0 h_37 me-2 me-md-3 fw-bold border_r_8px font_14">
                <span className="me-2">
                  <i className="fa-sharp fa-solid fa-arrow-rotate-left" />
                </span>
                Ongoing Project
              </div>
            )}
          </div>
        ) : (
          <div className="d-flex align-items-center flex-wrap">
            <button
              type="button"
              className={`btn btn_small ${projectType[type].color} mb-3 mb-md-0 px-2 py-0 h_37 me-2 me-md-3`}
            >
              <span className="me-2">
                <i className={projectType[type].class} />
              </span>
              {projectType[type].title}
            </button>
            <div className="d-flex bg_main border_r_8px mb-3 mb-md-0 px-2 py-0 h_37 me-2 me-md-3">
              <span className="text_black fw-bold font_14">
                ${hourlyRate}/hr
              </span>
              {duration > 0 && (
                <>
                  <span className="px-3 font_14">|</span>
                  <span className="text_black fw-bold font_14">
                    {duration} Weeks
                  </span>
                </>
              )}
            </div>
            {type < 2 && jobDocumentUrl.length > 0 && (
              <a
                type="button"
                className="btn btn_small bg_main ms-0 ms-mb-3 mb-3 mb-md-0 px-2 py-0 h_37"
                href={jobDocumentUrl}
              >
                <span className="me-2">
                  <i className="fa-solid fa-arrow-down-to-bracket" />
                </span>{" "}
                JobDocument.pdf
              </a>
            )}
            {type < 2 && (
              <div className="d-flex mb-3 mb-md-0">
                <p className="text_primary ms-3 font_12">
                  {moment(lastUpdate).fromNow()}
                </p>
                <p className="text_primary ms-3 d-flex align-items-center font_12">
                  <span className="dot" /> {totalApplicants} Applicants
                </p>
              </div>
            )}
          </div>
        )}
        <div className="d-flex align-items-center flex-wrap flex-md-nowrap mb-0 mb-md-2 mob_100 column_reverse">
          {showDeclineButton && (
            <button
              type="button"
              className="btn bg_warning btn_small text_redish mob_100 mb-2 mb-md-0 me-0 me-md-1"
              data-bs-toggle="modal"
              data-bs-target="#declineModal"
              onClick={() => handleDecline()}
            >
              {declineButtonTitle}
            </button>
          )}

          {buttonOnClick === undefined ? (
            <div className="d-flex bg_main border_r_8px mb-3 mb-md-0 px-2 py-0 h_37 me-2 me-md-3">
              <span className="text_black fw-bold font_14 text_ellipsis">
                {buttonTitle}
              </span>
            </div>
          ) : (
            <button
              disabled={checkStatus === "IN_ACTIVE" || disablePrimaryButton}
              type="button"
              className={
                buttonOnClick === undefined
                  ? `${buttonStyles[type].disableStyles} ${
                      disablePrimaryButton ? "bg-rw-disable" : ""
                    }`
                  : `${buttonStyles[type].enableStyle} ${
                      disablePrimaryButton ? "bg-rw-disable" : ""
                    }`
              }
              style={{ cursor: disablePrimaryButton ? "none" : "pointer" }}
              onClick={() => {
                if (buttonOnClick) {
                  buttonOnClick();
                }
              }}
            >
              {buttonTitle}
            </button>
          )}
        </div>
        {state?.shouldShowConfirmInActivateProjectDialog && (
          <ConfirmInActivateProjectDialog
            open={state?.shouldShowConfirmInActivateProjectDialog}
            onClose={handleCloseConfirmInActivateProjectDialog}
            onConfirm={() => handleProjectStatus(status.IN_ACTIVE)}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectStatusComp;
