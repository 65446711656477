import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import ProjectDetails from "components/MyProjects/ProjectDetails";
import {
  calculateProjectCost,
  getStudentHourlyRate,
  shouldShowPremiumFeatures,
} from "utils/common";
import { BudgetType, HourlyRate } from "utils/constants";
import QuoteSummary from "components/Projects/QuoteSummary";

const getSuggestedRate = (studentType: string) => HourlyRate[studentType];

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 36,
    width: 36,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

type Props = {
  user: any;
  description: string;
  responsibilties: string[];
  qualifications: string[];
  skills: string[];
  duration: number;
  jobDocumentUrl: string;
  status?: string;
  hourlyRate?: number;
  projectId: string;
  project?: any;
  handleChangeCapHour?: any;
  handleChangeHoursPerWeek?: any;
  handleChangeHourlyRate?: any;
  handleEditProject?: any;
};
const ListedProjectOverview = ({
  user,
  description,
  responsibilties,
  qualifications,
  skills,
  duration = 0,
  jobDocumentUrl = "",
  status = "",
  hourlyRate = 0,
  projectId,
  project = {},
  handleChangeCapHour = () => {},
  handleChangeHoursPerWeek = () => {},
  handleChangeHourlyRate = () => {},
  handleEditProject = () => {},
}: Props) => {
  const [isEditProjectHours, setIsEditProjectHours] = useState<boolean>(false);
  const {
    studentCompensation,
    platformFee,
    totalCost,
    discountAmount = 0,
  } = calculateProjectCost({
    hourlyRate: project?.hourlyRate,
    hoursPerWeek: project?.hoursPerWeek,
    duration: project?.duration,
    durationType: project?.durationType,
    retainerAmount: project?.retainerAmount,
    budgetType: project?.budgetType,
    promo: project?.promo,
  });

  const handleToggleEditBtn = async () => {
    if (!isEditProjectHours) {
      setIsEditProjectHours(true);
    } else {
      await handleEditProject();
      setIsEditProjectHours(false);
    }
  };
  const isCapRate = project?.isCapRate ? true : false;
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center flex-wrap">
          <div className="d-flex bg_main border_r_8px mb-3 mb-md-0 px-2 py-0 h_37 me-2 me-md-3">
            <span className="text_black fw-bold font_14">{`${hourlyRate}/hr`}</span>
            {duration > 0 && (
              <>
                <span className="px-3 font_14">|</span>
                <span className="text_black fw-bold font_14">
                  {duration} Weeks
                </span>
              </>
            )}
          </div>
          {jobDocumentUrl.length > 0 && (
            <a
              type="button"
              href={jobDocumentUrl}
              className="btn btn_small bg_main ms-0 ms-mb-3 mb-3 mb-md-0 px-2 py-0 h_37"
            >
              <span className="me-2">
                <i className="fa-solid fa-arrow-down-to-bracket" />
              </span>{" "}
              JobDocument.pdf
            </a>
          )}
        </div>
        <div>
          {isEditProjectHours && (
            <button
              type="button"
              className="btn bg_warning btn_small text_redish mob_100 mb-2 mb-md-0 me-0 me-md-1"
              data-bs-toggle="modal"
              data-bs-target="#declineModal"
              onClick={() => setIsEditProjectHours(false)}
            >
              Cancel
            </button>
          )}
          <button
            type="button"
            className="btn bg_light_secondary btn_small text_secondary mob_100"
            onClick={handleToggleEditBtn}
          >
            {!isEditProjectHours ? "Edit Project" : "Save Project"}
          </button>
        </div>
      </div>
      <div className="border-bottom box_shadow w-100 my-4" />
      {isEditProjectHours && project?.budgetType === BudgetType.HOURLY_RATE ? (
        <div className="border-[1px] rounded-md bg-white p-4 edit_project">
          <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
            Edit Budget
          </div>

          <div
            className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 mt-4 cursor-pointer"
            onClick={() => handleChangeCapHour(!isCapRate)}
          >
            <div className="w-full flex justify-between items-center">
              <div className="px-4">
                <div className="rw-gray-0 text-lg font-semibold">
                  Cap Timesheet Hours
                </div>

                <div className="text-xs font-medium text-rw-gray-75">
                  Prevent student from entering more than selected budget
                </div>
              </div>

              <div>
                <Radio className="border-[#D7DBE1]" checked={isCapRate} />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-between">
              <div>
                <div className="text-xl font-bold text-rw-black-dark">
                  Hourly Rate
                </div>
                {shouldShowPremiumFeatures(user) && (
                  <div className="text-xs font-semibold text-[#00000040]">
                    Suggested <span>${getSuggestedRate(project?.type)}</span>
                    /HR
                  </div>
                )}
              </div>

              <div className="text-right">
                <div className="text-xl font-bold text-rw-blue">
                  ${project?.hourlyRate}/HR
                </div>
                {shouldShowPremiumFeatures(user) && (
                  <div className="text-xs font-semibold bg-rw-blue-lightest rounded-full px-2 py-1">
                    <span className="text-[#00000080]">
                      Student Compensation:
                    </span>{" "}
                    <span className="text-rw-blue">
                      $
                      {getStudentHourlyRate(
                        project?.hourlyRate,
                        shouldShowPremiumFeatures(user),
                        project?.promo
                      )}
                      /HR
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-2 mt-4">
              <span className="text-[#D9D9D9] text-xs font-semibold">$15</span>
              <IOSSlider
                min={15}
                max={50}
                value={project?.hourlyRate}
                onChange={handleChangeHourlyRate}
              />
              <span className="text-[#D9D9D9] text-xs font-semibold">$50</span>
            </div>
          </div>

          {shouldShowPremiumFeatures(user) && (
            <>
              <div className="mt-8">
                <div className="flex justify-between">
                  <div className="text-xl font-bold text-rw-black-dark">
                    Hours Per Week
                  </div>

                  <div className="text-xl font-bold text-rw-blue">
                    {project?.hoursPerWeek} Hours
                  </div>
                </div>

                <div className="flex items-center gap-2 mt-4">
                  <span className="text-[#D9D9D9] text-xs font-semibold">
                    5
                  </span>
                  <IOSSlider
                    min={5}
                    max={40}
                    value={project?.hoursPerWeek}
                    onChange={handleChangeHoursPerWeek}
                  />
                  <span className="text-[#D9D9D9] text-xs font-semibold">
                    40
                  </span>
                </div>
              </div>

              <div className="border-[1px] border-[#D9D9D9] border-dashed my-8" />

              <QuoteSummary
                hourlyRate={project?.hourlyRate}
                hoursPerWeek={project?.hoursPerWeek}
                duration={project?.duration}
                durationType={project?.durationType}
                studentCompensation={studentCompensation}
                platformFee={platformFee}
                totalCost={totalCost}
                totalRetainerAmount={project?.retainerAmount}
                budgetType={project.budgetType}
                // onOpenPromoCode={handleOpenPromoCodeDialog}
                discountAmount={discountAmount}
                showPremiumFeatures={shouldShowPremiumFeatures(user)}
                promo={project.promo}
              />
            </>
          )}
        </div>
      ) : (
        <ProjectDetails
          description={description}
          responsibilties={responsibilties}
          qualifications={qualifications}
          skills={skills}
        />
      )}
    </div>
  );
};

export default ListedProjectOverview;
