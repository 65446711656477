import React, { useState, useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import TextField from "components/TextField";
import Images from "assets/images";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { getSignedUrlOfS3, signin, socialSignin } from "api";
import { AccountType, RUNWAY_TOKEN } from "utils/constants";
import { validateEmail } from "utils/string";
import posthog from "posthog-js";

type Props = {
  setUser: any;
  loggedIn: boolean;
};

// const StepLabels = {
//   EMAIL: "EMAIL",
//   PASSWORD: "PASSWORD",
// };

// const Steps = [
//   {
//     id: 1,
//     label: StepLabels.EMAIL,
//   },
//   {
//     id: 2,
//     label: StepLabels.PASSWORD,
//   },
// ];

export default function SignInContainer({ setUser, loggedIn }: Props) {
  // if (loggedIn) {
  //   window.location.href = `${process.env.REACT_APP_BASE_URL}/projects`;
  // }
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    email: "",
    password: "",

    errors: {},
    isContinueBtnPress: false,
    isDisableBtn: false,
  });

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleGetStarted = () => {
    navigate("/signup");
  };

  const isFormValid = () => {
    let isValid = true;
    let errors = {};

    const emailValidation = () => {
      if (!state?.email) {
        isValid = false;
        errors = { ...errors, email: "Email is required." };
      } else if (!validateEmail(state?.email)) {
        isValid = false;
        errors = { ...errors, email: "Email is invalid." };
      }
    };

    if (!state.isContinueBtnPress) {
      emailValidation();
    } else if (state.isContinueBtnPress && !state?.password) {
      emailValidation();
      errors = { ...errors, password: "Password is required." };
      isValid = false;
    }

    setState({ errors });

    return isValid;
  };

  const handleBack = () => {
    setState({ isContinueBtnPress: false });
  };

  const onSetUserAndGoHome = async (response: any, isSocialLogin: boolean) => {
    localStorage.setItem(RUNWAY_TOKEN, response?.token);
    if (isSocialLogin) localStorage.setItem("is_social_login", "true");
    const userInfo = response?.user;
    if (userInfo?.avatar && userInfo?.avatar?.key) {
      const signedUrlRes = await getSignedUrlOfS3(userInfo?.avatar?.key);
      if (signedUrlRes?.success) {
        userInfo.avatar = {
          ...userInfo.avatar,
          url: signedUrlRes.data.url,
        };
      }
    }
    setState({ isDisableBtn: false });
    setUser(userInfo);
    // amplitude.setUserId(response?.user?._id);
    const isStudentType = userInfo.accountType === AccountType.STUDENT;
    const is_first_login = await localStorage.getItem(`${response?.user?._id}`);
    if (isStudentType && is_first_login && is_first_login === "true") {
      navigate("/skills-assessment", { state: { fromSignupPage: true } });
      localStorage.setItem(`${response?.user?._id}`, "false");
    } else {
      navigate("/dashboard");
    }
  };

  const setUserAndProperties = (userId: any, userProperties: any) => {
    // Set the user ID
    amplitude.setUserId(userId);

    // Create an Identify object
    const identifyObj = new amplitude.Identify();

    // Set user properties on the Identify object
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in userProperties) {
      identifyObj.set(key, userProperties[key]);
    }

    // Send the identify call
    amplitude.identify(identifyObj);

    posthog.identify(userId, userProperties);
  };

  const handleSignin = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      setState({ isDisableBtn: true });
      const response = await signin({
        email: state?.email,
        password: state?.password,
      });

      if (response?.success) {
        onSetUserAndGoHome(response.data, false);
        setUserAndProperties(state.email, {
          user_id: response?.data.user?._id,
          email: state.email,
          isLogin: true,
          accountType: response?.data.user?.accountType,
        });
      } else {
        setUserAndProperties(state.email, {
          user_id: state.email,
          email: state.email,
          isLogin: false,
        });
        setState({ isDisableBtn: false });
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleGoogleSignin = async (token: string) => {
    try {
      const response = await socialSignin({
        token,
        provider: "google",
      });

      if (response?.success) {
        onSetUserAndGoHome(response.data, true);
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleContinue = () => {
    if (!isFormValid()) {
      return;
    }

    if (!state.isContinueBtnPress) {
      setState({ isContinueBtnPress: true });
    } else {
      handleSignin();
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogleSignin(codeResponse.access_token);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="middle_box pb-5">
      <div className="bg_white p-4 p-md-5 border_r_8px">
        <h3 className="fw-bold text_primary mb-4">Login</h3>
        <TextField
          type="text"
          name="email"
          placeholder="Email address"
          value={state?.email}
          onChange={handleChange}
          error={state?.errors?.email}
        />
        {state.isContinueBtnPress && (
          <TextField
            type="password"
            name="password"
            placeholder="Password"
            value={state?.password}
            onChange={handleChange}
            error={state?.errors?.password}
          />
        )}
        <button
          disabled={state.isDisableBtn}
          type="button"
          className={`btn bg_secondary w-100 mb-3 ${
            state.isDisableBtn ? "bg-rw-disable" : ""
          }`}
          onClick={handleContinue}
        >
          Continue
        </button>
        {state.isContinueBtnPress && (
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            onClick={handleBack}
          >
            Back
          </button>
        )}
        <p className="text_main font_14 text-center pt-4">
          Forgot password?
          {/* <a href="/signup" className="text_secondary text-decoration-none">
            {" "}
            Create Account
          </a> */}
          <Link
            to="/reset-password"
            className="text_secondary text-decoration-none"
          >
            {" "}
            Reset Password
          </Link>
        </p>
        <p className="text_main font_14 text-center pt-4">
          Don't have an account?
          {/* <a href="/signup" className="text_secondary text-decoration-none">
            {" "}
            Create Account
          </a> */}
          <Link to="/signup" className="text_secondary text-decoration-none">
            {" "}
            Create Account
          </Link>
        </p>

        {!state.isContinueBtnPress && (
          <>
            <div className="border_line">
              <span>or</span>
            </div>
            {/* <a
              href="/"
              className="text-center p-3 d-flex w-100 btn bg_transparent align-items-center mt-4 mb-3 bg_main border_r_8px"
            >
              <div className="text-center w-100 d-flex align-items-center justify-content-center">
                <img src={require("../../../assets/imgs/apple.png")} />
                <span className="text-center ms-3">Continue with Apple</span>
              </div>
            </a> */}
            <div className="text-center p-3 d-flex w-100 btn bg_transparent align-items-center mt-4 bg_main border_r_8px">
              <div
                className="text-center w-100 d-flex align-items-center justify-content-center"
                onClick={() => login()}
              >
                <img src={Images.GoogleIcon} />
                <span className="text-center ms-3">Continue with Google</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
